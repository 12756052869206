.frame_parent_customusamap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .frame_loading {
    margin-top: -7%;
  }
}
