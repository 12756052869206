@use "../../../scss/" as *;

// css customChart in page revenue
.frame_custom_Line_Chart {
  width: 100%;
  height: 100%;
 

  @include tablet {
    // overflow: auto;
    // box-sizing: border-box;
  }

  @include mobile {
    overflow: hidden;
    box-sizing: border-box;
  }

  .frame_title_Categories_revenue {
    width: 100%;
    margin-top: -0.8%;
    padding-bottom: 3%;
    display: flex;
    justify-content: left;
    align-items: center;

    span {
      font-family: $global-font;
      font-size: 1.4rem !important;
   
    }
    
    @include mobile {
      display: flex;
      justify-content: center;
      span {
        font-size: 1.1rem !important;
        display: flex;
        justify-content: center;
        text-align: center;

      }
     
    }
  }

  .frame_parent_baritem_chart {
    height: 100%;
    padding-top: 1%;  
    .frame_custom_barItem {
      height: 18%;
      font-size: 0.95rem;
    }

    @include tablet {
      height: 100%;
      // padding-top: 4.5%;
    }
  }
 
}
// ------------------------------------------------------------------

// css customChart in page expense
.frame_custom_Line_Chart {
  width: 100%;
  height: 100%;
  .frame_title_Categories_small {
    width: 100%;
    padding-top: 0%;
    padding-bottom: 3%;
    display: flex;
    justify-content: left;
    align-items: center;

    span {
      font-family: $global-font;

      font-size: 1.5rem !important;
    }

    @include mobile {
      span {
        font-size: 1.1rem !important;
      }
     
    }

    .span_style_expense {
      font-family: $global-font;
      font-size: 0.4rem;
    }
  }
  .frame_custom_barItem {
    height: 18%;
    font-size: 0.95rem;
  }
}
// ------------------------------------------------------------------
