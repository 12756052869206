


@use "../../../scss/" as *;

// css customChart in page revenue
.frame_horizontal_chart {
  width: 100%;
  height: 100%;
 

  @include tablet {
    overflow: auto;
    box-sizing: border-box;
  }

  @include mobile {
    overflow: hidden;
    box-sizing: border-box;
  }

  
  .frame_title_horizotal_Chart {

    // background-color: red;
    height: 5rem;
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 1%;


    span {
        font-family: $global-font;
      font-size: 1.3rem !important;
      color: white;
      padding-left: 5%;
      text-transform: uppercase;

      
    }

    @include mobile {
        justify-content: center;
    }
  }
 
}
