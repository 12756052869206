@use "../../scss/" as *;

.main_frame_revenuecard {
  background: linear-gradient(180deg, #6c7493 0%, #2e3038 100%);
  height: 100%;
  border-radius: 10px;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: $shadow;

  .span_name {
    text-align: left;
    margin-bottom: 6%;
    padding-top: 4%;
    span {
      font-size: 1.5rem;
      font-family: $global-font;
      text-transform: uppercase;
    }

    @include mobile {
      display: flex;
      justify-content: center;

      span {
        font-size: 1.1rem !important;
      }
    }
  }

  .frame_name_value_line {
    .main_frame_name_value {
      margin-bottom: 30px;

      .frame_name_value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 1.2rem;
          font-family: $global-font;
        }

        .frame_name_item_revenuecard {
          width: 80%;
          display: flex;
          align-items: center;
          text-align: left;
          span {
            font-size: 1.2rem;
            font-family: $global-font;
          }
        }
      }
    }
  }

  hr {
    border: 0;
    height: 1px;
    background-color: #ffffff;
  }
}

// Revenue card scroll ------------------------------------------------------------------------------------

.main_frame_revenuecard_sroll {
  background: linear-gradient(180deg, #6c7493 0%, #2e3038 100%);
  height: 100%;
  border-radius: 10px;
  color: white;
  box-shadow: $shadow;

  @include tablet {
    height: 700px;
  }

  @include mobile {
    height: 500px;
  }

  .span_name_scroll {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 6%;
    padding-top: 2%;
    height: 10%;

    span {
      font-size: 1.5rem;
      font-family: $global-font;
      text-transform: uppercase;
    }

    @include mobile {
      display: flex;
      justify-content: center;

      span {
        font-size: 1.1rem !important;
      }
    }
  }

  .frame_name_value_line_scroll {
    overflow: auto !important;
    box-sizing: border-box !important;
    height: calc(100vh - 45vh - 22px);

    @include tablet {
      height: 75%;
    }

    @include mobile {
      height: 80%;
    }
    .main_frame_name_value_scroll {
      margin-bottom: 30px;

      .frame_name_value_scroll {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;

        span {
          font-size: 1.2rem;
          font-family: $global-font;
        }

        .frame_name_item_revenuecard_sroll {
          width: 80%;
          display: flex;
          align-items: center;
          text-align: left;
          span {
            font-size: 1.2rem;
            font-family: $global-font;
          }
        }
      }
    }
  }

  .frame_hr_commission_cart {
    padding-left: 30px;
    padding-right: 30px;

    hr {
      border: 0;
      height: 1px;
      background-color: #ffffff;
    }
  }
}
