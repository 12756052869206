@use "../../scss/" as *;

.frame_transaction_card {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #6c7493 0%, #999999 100%);
  border-radius: 10px;
  box-shadow: $shadow;
  overflow: visible;
  box-sizing: border-box;

  .frame_children_elment_TM_Card {
    
    .frame_tile_name_total {
      height: 100%;
      padding-left: 5%;
      padding-right: 5%;

      .frame_title {
        display: flex;
        // background-color: red;
        @include mobile {
          span {
            font-size: 1.3rem !important;
          }
        }
        span {
          text-align: left;
          font-size: 1.5rem;
          color: white;
          margin-top: 5%;
          font-family: $global-font;
          text-transform: uppercase;
         
        }
      }

      .main_frame_name_total {
        width: 100%;
        height: 90%;
        margin-top: 5.5%;
        display: flex;
        align-items: center;
        overflow: visible;
        box-sizing: border-box;
        // background-color: aqua;
        // margin-top: 50px;

        @include tablet {
          height: 85%;
          margin-bottom: 20px;
        }

      

        .frame_name_total {
          width: 100%;
          height: 100%;
          // background-color: red;

       

          .frame_span_element {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            margin-bottom: 3%;

            span {
              font-size: 1.3rem;
              font-family: $global-font;
            }
          }
        }
      }
    }
  }
}
