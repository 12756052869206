@use '../../scss/' as *;


.frame_data_time {

    width: 100%;
    height: 4rem;
    display: flex;
    margin: auto;
    text-align: center;
    align-items: center;
    // background-color: aqua;

    @include tablet {
        width: 100%;
        height: 4rem;
        display: flex;
        margin: auto;
        text-align: center;
        align-items: center;
        // background-color: aqua;
        span {
            display: flex;
            margin: auto;
            justify-content: center !important;
            text-align: center !important;
        }
    }


    @include mobile {
        width: 100%;
        height: 4rem;
        display: flex;
        margin: auto;
        text-align: center;
        align-items: center;
        // background-color: aqua;
        span {
            display: flex;
            margin: auto;
            justify-content: center !important;
            text-align: center !important;
        }
    }
   

    span {
        color: $color-data-time !important;
        font-size: 1rem !important;
        font-family: $global-font;
        text-transform: uppercase;
        font-weight: 500;
    }
}