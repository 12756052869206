@use "../../scss/" as *;

.main_frame_dasboard {
  display: flex;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;

  .frame_menu_left {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 20% !important;
    // width: 19.2rem;
    // overflow: auto;
    // box-sizing: border-box;

    @include tablet {
      display: none;
      width: 0;;
    }

    @include mobile {
      display: none;
      width: 0;
    }

    transition: transform 1s ease;
    background-color: #1c1d21;

    .frame_logo {
      width: 85%;
      margin: auto;
      margin-top: 15%;
      height:60px;
      // background-color: red;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .frame_list_menubar {
      height: 60%;
      width: 90%;
      // background-color: red;

      margin: auto;
      margin-top: 13%;

      .frame_item_menu {
        width: 100%;
        height: 12%;

        margin-bottom: 10%;

        button {
          border: none;
          color: white;
          background-color: #1c1d21;
          // font-size: 25px;
          border-radius: 10px;
          font-family: $global-font;
          text-transform: uppercase;
          font-size: 1.3rem;
        }

        button:hover {
          background: $color-button-linear-hover;
        }

        .itemmenubar_active {
          button {
            background: $color-button-linear;
          }
        }

        button {
          width: 100%;
          height: 100%;
        }
      }
    }

    .frame_note_source_data {

      // background-color: red;
      height: 25%;
      // background-color: aqua;
      margin-bottom: 10px !important;
      
      span {
        color: white;
        font-family: $global-font;
        text-transform: uppercase;
        font-size: 1.1rem;
      }

      .frame_powerbi {

        margin-top: 5%;

        span {

          color: white;
          font-family: $global-font;
          text-transform: uppercase;
          font-size: 1.1rem;
        }


        .frame_powerBi_text_img {

          display: flex;
          align-items: center;
          justify-content: center;
         


          .frame_image_powerbi {
            margin: auto;
  
            width: 35px;
            height: 35px;
            cursor: pointer;
            padding-top: 5px;
            // background-color: aqua;
            margin: 0;
            // margin: auto;
  
            img {
  
              width: 100%;
              height: 100%;
            }
          }

          span {
            padding-top: 6px;
            align-self: center;
            // background-color: red;
            margin-left: 0;
            color: #F2C811;
            text-transform: none;
            padding-left: 5px;
            cursor: pointer;
          }


        }


        


      }
    }
  }

  

  .main_frame_outlet {
    width: calc(100% - 20%);
    margin-left: 20%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;

    @include tablet {

      width: 100% ;
      // background-color: red;
      margin-left: 0;
    }

    @include mobile  {
      margin-left: 0;
      width: 100%;
      overflow-y: scroll;
     
    }
  }


  

}


.frame_modal_powerBi {

  width: calc(100vw - 30vw) !important;
  height: calc(100vh - 35vh);


  // background-color: red;


  .frame_main_powerbi {
    position: relative;

    // width: calc(100vw - 30vw);
    width: 100%;
    padding-top: 3%;
    height: calc(100vh - 30vh)
  }
}