@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

span {
  font-family: "Lato", sans-serif;
}

.App {
  text-align: center;
  box-sizing: border-box;
}

a {
  color: black;
  text-decoration: none;
}

button {
  cursor: pointer;
}



::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
/* 
::-webkit-scrollbar {
  display: none; 
}

 */
