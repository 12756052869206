@use "../../scss/" as *;
.main_frame_transaction {
  justify-content: center;
  align-items: center;
  height: 99.5%;
  width: 98%;
  margin: auto;
  // overflow: visible;
  // box-sizing: border-box;

 

  .frame_datetime_transaction {
    width: 98% !important;
    display: flex;
    margin: auto;
    // background-color: red;
  }
 

  @include tablet {
    height: auto;
    margin-bottom: 30px !important;
  }

  @include mobile {
    height: auto;
    width: 96% !important;
    margin-bottom: 20px !important;
  }
}

.frame_top_card {
  height: 30%;
  // background-color: #6c7493;
  width: 98%;
  display: grid;
  margin: auto !important;
  // overflow: auto;
  // box-sizing: border-box;
  // background-color: red;

  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  

  @include tablet {
    grid-template-columns: repeat(1, 1fr);
    margin: auto;
    height: 82%;
    width: 100% !important;
    row-gap: 20px;

    margin-bottom: 30px;
  }

  span {
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-family: $global-font;
    color: white;
  }
}

.frame_transactionbymonht {
  background: linear-gradient(0deg, #6c7493 0%, #2e3038 100%);
  // height: 53.5%;
  height: 56%;
  // height: calc(100vh - 45vh);
  width: 98%;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: $shadow;
  margin-top: 2.8%;

  @include tablet {
    height: 430px !important;
    // margin-bottom: 100px;
  }

  @include mobile {
    // height: 430px !important;
    width: 100% !important;
    height: 300px !important;
    margin-top: 20px !important;


  }

  .frame_title_chart_transaction {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: left;
    align-items: center;

    // background-color: rebeccapurple;

    @include mobile {
      display: flex;
      justify-content: center;
      margin-left: 0px;
      padding-top: 15px;
      height: 20%;

      span {
        font-size: 1.2rem !important;
        padding-left: 0px !important;
      }
    }

    span {
      font-family: $global-font;
      padding-left: 3%;
      font-size: 1.5rem;
      color: white;
    }
  }

  .frame_cutome_layout_linechart_transaction {
    height: 80%;
    width: 100%;
    margin-top: 10px;
  

    @include tablet {
      overflow: auto;
      box-sizing: border-box;
    }

    @include mobile {
      height: 80%;
      // overflow: auto;
      margin-top: 0px;
      margin-top: -17px;

      overflow: auto;
      box-sizing: border-box;
  
    }

    .frame_line_chart_transations {
      height: 100% !important;
      // background-color: red;
      width: 100%;

      @include tablet {
        width: 100%;
      }

      @include mobile {
        width: auto;
        display: flex;
        justify-content: center;
        // // overflow-x: scroll;
        // // width: 50%;
        width: 140%;
        height: 100% !important;
      }

      .frame_parent_line_Chart_transactions {
        // width: 110% !important;
        // margin-left: -4.1%;
        margin-top: -0.8%;
        // width: 100% !important;
        height: 100%;
        // width: 120%;

        // background-color: rebeccapurple;

        @include tablet {
          width: 100%;
          margin-left: 0px;
        }

        @include mobile {
          height: 100%;
          // margin-left: 10px;
          margin-top: 0px;
          // background-color: red;
        }
      }
    }
  }
}
