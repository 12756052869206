@use "../../scss/" as *;

.frame_total_transaction {
  width: 100%;
  height: 100%;
  background: linear-gradient(140deg, #6c7493 0%, #2e3038 100%);

  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: $shadow;

  @include mobile {
    
  }
 
  .frame_title_icon_value {
    width: 100%;

    @include mobile {
      span {
        font-size: 1.1rem !important;
      }
    }

    span {
      font-size: 1.5rem;
      text-transform: uppercase;
    }

    .frame_title_icon {
      margin-bottom: 3%;
    }

    .frame_value {
      span {
        font-family: $global-font;
        font-weight: 600;
        font-size: 40px;
      }
    }
  }
}

// // ----------------------------------------------------------------------------------

.frame_total_budget {
  width: 100%;
  height: 100%;
  background: linear-gradient(140deg, #6c7493 0%, #2e3038 100%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .frame_title_icon_value {
    width: 100%;

    @include mobile {

      padding-top: 10px;
      padding-bottom: 10px;
      span {
        font-size: 1.1rem !important;
      }
    }

    span {
      font-size: 1.4rem;
      text-transform: uppercase;
      font-family: $global-font;
    }

    .frame_title_icon {
      margin-bottom: 4%;
    }

    .frame_value {
      span {
        font-family: $global-font;
        font-weight: 500;
        font-size: 1.7rem;
      }
    }

    .frame_sub_contents {
      margin-top: 4%;
      span {
        font-size: 1rem;
        text-transform: capitalize;
        font-family: $global-font;
      }
    }
  }
}

// //-------------------------------------------------------------------------------

.frame_total_expense {
  width: 100%;
  height: 100%;
  background: linear-gradient(140deg, #6c7493 0%, #2e3038 100%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: $shadow;

  .frame_title_icon_value {
    width: 100%;

    span {
      text-transform: uppercase;
      font-family: $global-font;
      font-size: 1.4rem;
      word-spacing: 5px;
    }

    .frame_title_icon {
      margin-bottom: 3%;
    }

    .frame_value {
      span {
        font-family: $global-font;
        font-weight: 600;
        font-size: 1.4rem;
      }
    }
  }
}
// ---------------------------------------------------------------------------------

.frame_total_expense_second {
  width: 100%;
  height: 100%;
  background: linear-gradient(140deg, #6c7493 0%, #cad5f3 100%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: $shadow;

  .frame_title_icon_value {
    width: 100%;

    span {
      text-transform: uppercase;
      font-family: $global-font;
      font-size: 1.4rem;
      word-spacing: 5px;
    }

    .frame_title_icon {
      margin-bottom: 3%;
    }

    .frame_value {
      span {
        font-family: $global-font;
        font-weight: 600;
        font-size: 1.4rem;
      }
    }
  }
}
// budgetcardlist-------------------------------------------------


.frame_total_budget_card_list {
  width: 100%;
  height: 100%;
  // background: linear-gradient(140deg, #6c7493 0%, #2e3038 100%);
  // border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;


  .frame_title_icon_value_card_list {
    width: 100%;

    @include mobile {

      padding-top: 10px;
      padding-bottom: 10px;
      span {
        font-size: 1.1rem !important;
      }
    }

    span {
      font-size: 1.4rem;
      text-transform: uppercase;
      font-family: $global-font;
    }

    .frame_title_icon_card_list {
      margin-bottom: 4%;
    }

    .frame_value_card_list {
      span {
        font-family: $global-font;
        font-weight: 500;
        font-size: 1.7rem;
      }
    }

    .frame_sub_contents {
      margin-top: 4%;
      span {
        font-size: 1rem;
        text-transform: capitalize;
        font-family: $global-font;
      }
    }
  }
}