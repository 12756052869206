@use '../../../scss/' as *;

.frame_custome_piechart {

    height: 100%;
    width: 100%;
    background-color: transparent;
    position: relative;


    @include tablet {

        height: 100%;

        // height: 500px;
       
    }

    @include mobile {
        // height: 85%;
        // margin-top: 8%;
    }

    .frame_piechart_revenue {

        width: 100%;
        height: 80%;
        z-index: 999;


        @include tablet {
            height: 450px;
        }

        
        @include mobile {
            height: 300px;
        }
    }
}