@use '../../../scss/' as *;

.frame_main_linechart_budget {

    width: 100%;
    height: 100%;
    position: relative;

    @include tablet {

        height: 25rem;
    }
  

    @include mobile {

        // height: auto;
        height: 17rem !important;
        // // background-color: rebeccapurple;
        // width: 40rem;
        // overflow: auto;
        // box-sizing: border-box;
    }
   
    .main_line_echart {

        height: 100%;
        width: 100%;

        @include mobile {

            // height: 50rem !important;
            // background-color: rgb(0, 255, 42);
            height: 100%;
            // background-color: red;
            padding-top: 10px;
        }

    }
}