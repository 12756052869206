@use "../../../scss/" as *;

.dark-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  display: none;
}

.dark-screen.active {
  display: block;
}

.main_frame_piechart_expense {
  width: 100%;
  height: 100%;

  @include mobile {
    height: 80%;
  }

  .frame_parent_piechart_expense {
    width: 100%;
    height: 100%;
    z-index: 4;
    padding-left: 0px;
    position: relative;
  }
}
