@use "../../../scss/" as *;

.main_frame_register {
  background-color: #1c1d21;
  height: 100vh;
  overflow: auto !important;
  box-sizing: border-box !important;

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-family: $global-font;
  }

  .parent_frame_element_register {

    width: 100%;
    height: 100%;
    // background-color: red;
    display: flex;
    align-items: center;


    .frame_element_register {
        // height: 80%;
        width: 60%;
        margin: auto;
        align-items: center;
        border-radius: 20px;
        // background-color: aqua;
        // height: 100%;
        // padding-top: 10%;
        
    
        @include tablet {
          width: 80% !important;
        //   height: 100% ;
        //   padding-top: 15%;
          
        }
    
        @include mobile {
          width: 100%;
        
        }
    
        .frame_logo_login {
          margin: auto;
    
          width: 40%;
        //   margin-top: 2%;
          margin-bottom: 2%;
    
          @include tablet {
            margin-top: 15%;
            width: 60%;
          }
    
          @include mobile {
            margin-top: 10%;
            width: 90%;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
    
        .frame_title_signin {
          margin-top: 2%;
          margin-bottom: 2%;
    
          span {
            font-family: $global-font;
            font-size: 2.5rem;
            font-weight: 600;
          }
        }
    
        .frame_sub_title {
    
            @include mobile {
                margin-top: 1rem;
            }
        }
    
        .fram_item_login_form {
          width: 40%;
          margin: auto;
          //   border: 1px solid grey;
          //   border-radius: 20px;
          //   border-style: dashed;
          //   padding: 8%;
    
          //   padding-top: 8%;
    
          @include tablet {
            width: 60%;
            margin-bottom: 12% !important;
          }
    
          @include mobile {
            width: 80%;
            
          }
    
          .frame_input {
            margin-bottom: 5%;
    
            .element_input_label_name_register {
              margin-top: 8%;
              margin-bottom: 8%;
    
              justify-content: left;
              text-align: left;
    
              label {
                margin-bottom: 10px;
                display: block;
              }
    
              .input_field {
                height: 3rem;
                font-size: 1rem;
              }

              .input_field input:focus {
                background-color: white !important; 
                outline: none; 
               
              }
            }
    
            .element_input_label_email_register {
              // margin-top: 10%;
              // background-color: red;
              margin-bottom: 8%;
    
              justify-content: left;
              text-align: left;
    
              label {
                margin-bottom: 10px;
                display: block;
              }
    
              .input_field {
                height: 3rem;
                font-size: 1rem;
              }
    
              //   .input_field:hover {
              //     // border: 1px solid white;
              //   }
            }
    
            .element_input_label_password {
              // margin-top: 10%;
              // background-color: red;
              // margin-bottom: 10%;
    
              justify-content: left;
              text-align: left;
    
              label {
                margin-bottom: 10px;
                display: block;
              }
    
              .input_field {
                height: 3rem;
                font-size: 1rem;
              }
    
              //   .input_field:hover {
              //     // border: 1px solid white;
              //   }
            }
            .frame_forgot_password {
              // display: flex;
              text-align: left;
              text-align: right;
    
              .forgot-password {
                span {
                  //   color: rgb(84, 84, 254);
                  color: rgba(228, 4, 4, 0.896);
                }
              }
            }
          }
    
          .frame_button_register {
            margin: 10% 0 5% 0;
            height: 3rem;

            @include mobile {
              margin-top: 11%;
            }
    
            .color_button {
              height: 100%;
              background-color: rgb(156, 5, 5);
              font-size: 1rem;
              color: white;
              border: none;
            }
    
            .color_button:hover {
              border: 1px solid white;
              background-color: rgb(177, 12, 12);
            }
          }
    
          .frame_create_account {
            .icon_back {
              color: white;
              margin-right: 5px;
            }
            .frame_sign_up {
              span {
                // color: rgb(84, 84, 254);
                color: rgba(228, 4, 4, 0.896);
              }
            }
    
           
          }
        }
      }
    }

  }


  

.frame-modal {
  top: 50%;
  height: 20%;
  //   width: 30% !important;

  @include mobile {
    top: 40%;
    width: 80% !important;
  }
}

.custom-modal-body {
  // height: 100px;
  position: relative;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  //   width: 525px;
  // background-color: red;

  p {
    font-family: $global-font;
    font-size: 1rem;
    // text-align: center;
  }
}
