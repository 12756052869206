@use "../../scss/" as *;

.main_frame_commission {
  justify-content: center;
  align-items: center;
  width: 96%;
  margin: auto;
  padding-bottom: 1%;
  overflow: visible;

  @include tablet {
    padding-bottom: 0px;
  }

  @include mobile {
    height: auto;

    padding-bottom: 0px;
  }

  .frame_top_chart {
    background: linear-gradient(140deg, #6c7493 0%, #2e3038 100%);
    height: (100vh - 60vh);
    position: relative;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: $shadow;

    @include tablet {
      height: 400px !important;
      width: 100% !important;
    }

    @include mobile {
      height: 400px !important;
      width: 100% !important;
    }

    .frame_title_top_chart_commission {
      display: flex;
      text-align: left;

      height: 10%;

      @include tablet {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 14%;
        span {
          font-size: 1.2rem !important;
        }
      }

      @include mobile {
        height: 10%;
        display: flex;
        justify-content: center;
        text-align: center;
        span {
          font-size: 1.1rem !important;
          text-align: center;
        }
      }

      span {
        padding-left: 20px;
        padding-top: 20px;
        font-size: 1.4rem;
        text-transform: uppercase;
      }
    }

    .areline_chart_new {
      height: 90%;
      width: 100%;

      overflow: auto;
      box-sizing: border-box;

      .frame_arelinechart {
        height: 100%;

        overflow: hidden;

        @include tablet {
          width: 95%;
          height: 95%;
          margin: auto;
        }

        @include mobile {
          display: flex;
          justify-content: center;
          height: 100%;

          margin: auto !important;
          margin-left: 0px;
          width: 500px;
        }

        .frame_parent_line_Chart {
          width: 110%;
          height: 100%;
          position: relative;
          margin-left: -4.5%;

          @include tablet {
            height: 100%;
          }

          @include mobile {
            width: 96%;
            height: 100%;
            margin-left: 20px;
            padding-top: 1rem;
          }
        }
      }
    }
  }

  .frame_bottom_commission {
    height: auto;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: 63.7% 35%;
    column-gap: 20px;
    margin-bottom: 20px;

    @include tablet {
      height: 100%;
      grid-template-columns: repeat(1, 1fr);

      margin-bottom: 2% !important;
    }

    @include mobile {
      height: 100%;
      margin-bottom: 13px !important;
    }

    .frame_left_bottom_Commission {
      width: 100%;

      @include tablet {
        margin-bottom: 3%;
      }

      .frame_geomap_commission {
        height: calc(100vh - 45vh);
        width: 100%;
        margin-bottom: 20px;
        border: 2px solid rgb(192, 192, 192);
        border-radius: 10px;
        box-shadow: $shadow;

        @include tablet {
          height: 600px !important;
        }

        @include mobile {
          height: 420px !important;
        }

        .frame_title_geomap_commission {
          height: 10%;
          padding-top: 2%;
          display: flex;
          text-align: left;
          padding-left: 4%;
          span {
            font-family: $global-font;
            font-size: 1.35rem;
            text-transform: uppercase;
            color: #6c7493;
          }
        }

        .frame_geomap {
          height: 90%;
          width: 99%;
          margin-top: -1%;
          color: #f9f9dd;
        }
      }

      .frame_card_commission {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-content: center;
        align-items: center;
        column-gap: 20px;
        color: rgb(38, 38, 38);
      }
    }

    .frame_right_bottom_commission {
      width: 100%;
      height: 100%;

      @include tablet {
        height: 100%;
      }

      @include mobile {
        height: 100%;
      }

      .frame_right_top_card {
        width: 100%;
        height: 30%;
        margin-bottom: 30px;

        @include tablet {
          height: 200px !important;
        }

        @include mobile {
          height: 200px !important;
          margin-bottom: 15px;
          margin-top: 5px !important;
        }
      }

      .frame_right_bottom_card {
        height: calc(100% - 30% - 30px);
        justify-content: center;
        align-items: center;

        @include tablet {
          height: calc(100% - 50%) !important;
        }

        @include mobile {
        }

        .style_title {
          margin-top: 5% !important;
          margin-bottom: 5%;
        }

        span {
          display: flex;
          text-align: center !important;
        }
      }
    }
  }

  span {
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
  }
}
