@use '../../../scss/' as *;

.frame_logout_icon {

    color:  white;
    font-size: 1.1rem;
    font-family: $global-font;
    cursor: pointer;

    margin-top: 5%;
    // background-color: red;
    // margin-bottom: 5% !important;

    .icon_logout {
        transform: rotate(180deg);
        margin-right: 0.5rem;
        // font-size: 1.2rem;

    }
}