@use "../../scss/" as *;

.main_frame_expense {
  width: 98%;
  overflow: hidden;
  padding-bottom: 1%;
  margin: auto;

  @include mobile {
    margin-bottom: 5px !important;
  }

  .frame_datetime_expense {
    width: 98% !important;
    display: flex;
    margin: auto;
    // background-color: red;
  }

  .frame_top_total_expense {
    height: calc(100vh - 49vh);
    width: 98%;
    margin: auto !important;
    margin: 0% 0% 2% 0%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;

    @include tablet {
      overflow: auto;
      box-sizing: border-box;
      height: 30rem !important;
    }

    @include mobile {
      overflow: auto;
      box-sizing: border-box;
    }

    span {
      text-align: center;
      align-items: center;
      font-size: larger;
      color: white;
    }

    .frame_main_cart_customChart {
      display: grid;
      height: 100%;
      align-items: center;
      grid-template-columns: 25% 10% 25% 9% 31%;

      @include tablet {
        grid-template-columns: 300px 100px 250px 100px 500px;
      }

      .frame_cart_top_total_expense {
        height: 30%;
        width: 100%;
      }
      .frame_img_line {
        width: 100%;
        margin-top: 19%;

        img {
          width: 100%;
        }
      }

      .frame_img_line_two {
        width: 100%;
        margin-top: 33%;
        img {
          width: 100%;
        }
      }
      .frame_cart_top_total_expense_twocard {
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .frame_first_columns {
          width: 100%;
          height: 40%;
        }
        .frame_second_columns {
          width: 100%;
          height: 40%;
        }
      }

      .frame_top_custom_lineChart_expense {
        height: 80%;
        padding: 5%;
        background: linear-gradient(180deg, #7284b6 0%, #cad5f3 100%);
        border-radius: 10px;
        box-shadow: $shadow;

        .span_style_expense {
          font-family: $global-font;
          font-size: 1.1rem;
        }
      }
    }
  }

  .frame_total_expensebymonth {
    height: calc(100vh - 55vh);
    width: 98%;
    margin: auto !important;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #6c7493 0%, #2e3038 100%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    // margin: 0% 0% 2% 0%;
    box-shadow: $shadow;
    margin-bottom: 2% !important;

    @include tablet {
      height: 500px;
    }

    @include mobile {
      height: 360px !important;
    }
    span {
      text-align: center;
      align-items: center;
      color: white;
    }

    .frame_title_expensebymonth {
      display: flex;
      justify-content: left;
      padding-top: 2.2%;
      padding-left: 3%;

      @include mobile {
        display: flex;
        justify-content: center;
        padding-top: 1rem;

        span {
          font-size: 1.1rem !important;
          // text-align: center;
        }
      }

      span {
        font-family: $global-font;
        font-size: 1.5rem;
      }
    }

    .frame_cutome_layout_singlechart_expense {
      height: 100%;
      width: 100%;

      @include mobile {
        height: 88%;
        overflow: auto;
        box-sizing: border-box;
      }
      // overflow: auto;
      // box-sizing: border-box;
      .frame_singlechart_expense {
        width: 98%;
        height: 90%;
        margin-top: -1%;
        margin-left: 2%;

        @include mobile {
          width: 600px;
          height: 100%;
        }
      }
    }
  }

  .frame_chart_categories_new {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    width: 98%;
    margin: auto !important;
    margin-bottom: 2% !important;

    @include tablet {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px;
    }

    @include mobile {
      margin-top: 20px !important;

      margin-bottom: 20px !important;
    }

    .frame_left_chart_catergoris {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: linear-gradient(180deg, #6c7493 0%, #2e3038 100%);
      font-family: $global-font;
      box-shadow: $shadow;
      @include tablet {
        height: 500px;
      }

      @include mobile {
        overflow: auto;
        box-sizing: border-box;
        height: 410px !important;
        overflow: hidden;
      }

      .frame_title_chart {
        width: 100%;
        height: 100%;

        .frame_title_revenue {
          width: 90%;
          // height: calc(100vh - 92.6vh);
          padding-left: 4%;
          // padding-top: 3.5%;
          // padding-bottom: 3%;
          height: 20%;
          display: flex;
          justify-content: left;
          align-items: center;

          @include mobile {
            height: 40px;

            display: flex;
            justify-content: center;
            padding-top: 20px;

            span {
              font-size: 1.2rem !important;
            }
          }
          span {
            display: flex;
            text-align: left;
            font-size: 1.4rem;
            color: white;
            line-height: 1;
            font-family: $global-font;
            text-transform: uppercase;
          }
        }

        .frame_cutome_layout_piechart_expense {
          height: 80%;
          width: 100%;

          .frame_main_pieChart {
            margin-top: -3.5%;
            // background-color: rebeccapurple;

            // margin-bottom: 5%;
            width: 100%;
            height: 20rem;

            @include tablet {
              margin-top: -6%;
              height: 26rem !important;
            }

            @include mobile {
              // width: 450px;
              // height: 10rem !important;
              // background-color: aqua;

              margin-top: 0px;
            }
          }
        }
      }
    }

    .frame_right_market_expense_new {
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: linear-gradient(180deg, #6c7493 0%, #2e3038 100%);
      font-family: $global-font;
      box-shadow: $shadow;
    }
  }

  .frame_categories_vendors_new {
    justify-content: center;
    align-items: center;
    width: 98%;
    height: calc(100vh - 50vh);
    margin: auto !important;
    margin-bottom: 2% !important;
    border-radius: 10px;
    background: linear-gradient(180deg, #6c7493 0%, #2e3038 100%);
    font-family: $global-font;
    box-shadow: $shadow;

    @include tablet {
      height: 40rem;
    }

    @include mobile {
      height: 35rem;
      margin-bottom: 20px !important ;
    }
  }

  .frame_categories_vendors_new_bottom {
    justify-content: center;
    align-items: center;
    width: 98%;
    height: calc(100vh - 50vh);
    margin: auto !important;
    margin-bottom: 1% !important;
    border-radius: 10px;
    background: linear-gradient(180deg, #6c7493 0%, #2e3038 100%);
    font-family: $global-font;
    box-shadow: $shadow;

    @include tablet {
      height: 40rem;
    }

    @include mobile {
      height: 35rem;
      margin-bottom: 2% !important;
    }
  }
}

