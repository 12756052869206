@use '../../../scss/' as *;

  
.frame_vertical_chart_tooltip {

    height: 100%;

    .frame_title_vertical_chart_tooltip {

        height: 20%;
        // background-color: red;
        display: flex;
        justify-content: left;
        align-items: center;

        span {
            color: white;
            font-family: $global-font;
            font-size: 1.4rem;
            text-transform: uppercase;
            padding-left: 5%;

        }

        @include mobile {

            span {
                padding-left: 0;
                
            }
        }

    }


.frame_line_left_column_right {

    height: 80%;
    display: flex;
    padding-left: 3%;
    padding-right: 3%;
    color: white;
    

    @include tablet {

        overflow: auto;
        box-sizing: border-box;
        padding-top: 2rem;
    }

    

    .frame_line_left_M {
        // background-color: black;
        height: 100%;
        // background-color: aqua;
        width: 6%;

        @include tablet {
            width: 10%;
        }

        @include mobile {
            // width: 50%;
            // background-color: red;
        }

        

        .top_left_M  {

            height: 80%;
            width: 100%;
            // background-color: forestgreen;
            display: flex;
            flex-direction: column; 
            justify-content: flex-end;
            flex: 1; 
            display: flex;
            flex-direction: column; 
            justify-content: flex-end;
            border-right: 1px solid white;
            // z-index: 1000; 
            
            

            .value_M {

                height: 100%;
                // display: flex;
                // align-items: flex-end;
                // justify-content: center;
                // z-index: 1000;
                // background-color: antiquewhite;
                // border-top: 1px solid gray;
                position: relative;
                margin-right: 40%;

                @include mobile {

                    margin-right: 100%;
                }

                    span {
                        position: absolute;
                        margin-top: -10px;
                        // margin-right: 10px !important;
                    }
            }
        }

        .bottom_left_M  {

            height: 20%;
            width: 100%;
            position: relative;
            padding-right: 40% !important;

            @include mobile {
                margin-right:100% !important;
                // background-color: red;
                span {
                    margin-right: 100px !important;
                    // color: aqua;
                    margin-top: -12px;
                    position: absolute;
                    margin-left: -20px;
                }
            }

            

            // span {
            //     position: absolute;
            //     margin-top: -10px;
            // }

           
            // background-color: rgb(204, 24, 24);
        }
    }

    
    .frame_line_left_x_value {

        height: 100%;
        width: 6%;
        // background-color: rgb(0, 55, 255);

        .frame_x_bottom_left {
            width: 100%;
            height: calc(100vh - 70vh) ;
            // background-color: bisque;
            border-right: 1px solid white;
            display: flex; 
            flex-direction: column;
            color: white;

            .frame_value_M_line {
                flex: 1; 
               height: 100%;
            //    background-color: rebeccapurple;
               display: flex;
               flex-direction: column; 
               justify-content: flex-end;


               .value_M {

                height: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
               }
               
             
            }
        }
    }



    .frame_column_right_value {

        width: 90%;
        height: 100%;
        // background-color: rgb(4, 37, 37);
        position: relative;

        @include mobile {
            width: 88%;
        }
        
        

        .frame_main_chart_vertical_tooltip {
            position: absolute;
            height: 100%;
            width: 100%;
            // background-color: antiquewhite;
            display: flex;
            justify-content: space-between; 
            align-items: flex-end;
            // z-index: 3;
            
    
            .frame_custom_barItem_tooltip {
                height: 100%;
                // background-color: red !important;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;    
                // z-index: 3;
                position: relative;
            }
            
        }

        .dashed_line_map {
            position: relative;
            width: 100%;
            height: 80%;
            // background-color: rgb(187, 10, 10);
            z-index: 2;
            border-bottom: 1px solid white;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            flex: 1;

            @include tablet {
                // background-color: rebeccapurple;
                width: 61rem;
            }


            .frame_each_dashed_line {
                height: 50%;
                width: 100%;
                background-image: linear-gradient(to right, white 50%, transparent 50%);
                background-position: top;
                background-size: 15px 1px;
                background-repeat: repeat-x;
                border-width: 4px !important;

            
            }
        }
        
    
    }
    
}
    

    
}



.dark-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); // Adjust opacity as needed
    z-index: 4; // Make sure it's above other elements
  }
  


