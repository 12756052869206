@use "../../scss/" as *;

.main_frame_menuPage {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden !important;

  .left_frame {
    width: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    @include tablet {
      display: none;
    }

    @include mobile {
      display: none;
    }

    img {

      width: 95%;
      height: 100%;
      align-items: center;
      justify-content: center;

      @include tablet {
        width: 90%;
        // height:;
      }

      @include mobile {
        width: 90%;
      }
    }
  }

  .right_frame {
    background-color: #1c1d21;
    color: white;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    @include tablet {
      width: 100%;
    }

    @include mobile {
      width: 100%;
    }

    .children_right_frame {
      height: 80%;
      align-items: center;
      width: 80%;

      h2 {
        text-align: left;
        font-size: 200%;
        font-family: $global-font;
        text-transform: uppercase;

        
        @include tablet {
          font-size: 1.5rem;
          text-align: center;
        }

        @include mobile {
          font-size: 1.5rem;
          text-align: center;
        }
      }

      .frame_menu {
        margin: auto;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 90%;
        // background-color: rebeccapurple;

        .frame_button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          width: 95%;
          height: 17%;
          margin-bottom: 3%;

          button {
            width: 95%;
            height: 95%;
            cursor: pointer;
            background-color: #1c1d21;
            color: white;
            outline: none;
            border: none;
            border-radius: 10px;
            display: flex;
            align-items: center;
            // background-color: red;
            justify-content: center;

            span {
              font-size: 1.3rem;
              font-family: $global-font;
              text-transform: uppercase;
            }
          }

          button:hover {
            color: white;
            background: linear-gradient(90deg, #cd171f 0%, #670c10 100%);
          }
        }
      }
    }
  }
}
