@use "../../scss/" as *;

.floadbutton {

    display: none;

    @include tablet {
        display: block;

    }

    @include mobile {
        display: block;
    }
}
.frame_drawermode.ant-drawer-content {
  background-color: #1c1d21;
  color: white;
  padding: 0;

  .frame_menu_left {
    transition: transform 1s ease;
    // background-color: red;
    

    .frame_logo {
      display: flex;
      justify-content: center;
    }

    .frame_list_menubar {
      height: 100%;
      width: 100%;

      margin: auto;
      margin-top: 13%;

      .frame_item_menu {
        width: 100%;
        height: calc(100vh - 91vh);

        margin-bottom: 4%;

        button {
          border: none;
          color: white;
          background-color: #1c1d21;
          // font-size: 25px;
          border-radius: 10px;
          font-family: $global-font;
          text-transform: uppercase;
          font-size: 1.3rem;
        }

        button:hover {
          background: $color-button-linear-hover;
        }

        .itemmenubar_active {
          button {
            background: $color-button-linear;
          }
        }

        button {
          width: 100%;
          height: 100%;
        }
      }
    }

    .frame_note_source_data {
      display: flex;
      justify-content: center;
      text-align: center;
    
      padding-top: 10%;

      span {
        color: white;
        font-family: $global-font;
        text-transform: uppercase;
        font-size: 1rem;
      }
    }
  }
}

// .frame_drawermode .ant-drawer-content {
//     background-color: #f0f2f5;
//   }

//   .frame_drawermode .ant-drawer-header {
//     background-color: #4a4a8e;
//     color: white;
//   }

//   .frame_drawermode .ant-drawer-title {
//     color: white;
//   }
