
@use '../../../scss/' as *;

.columns_chart_budget {

    width: 100%;
    height: 100%;

    @include mobile {
        width: 180%;
    }
}