@use "../../../../scss/" as *;

.main_frame_barItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    font-family: $global-font;
  }

  .frame_categories_value {
    display: flex;
    justify-content: space-between;

    .frame_categories {
      padding-bottom: 3px;
    }
  }
}

.frame_line_percentage {
  background-color: white;
  height: 15px;
  border-radius: 10px;
}
