@use "../../scss/" as *;

.frame_main_table_budget {
  height: 100%;
  width: 100%;
  border: 1px solid gray;
  border-radius: 10px;
  box-shadow: $shadow;

  .frame_title_table {
    height: 8%;
    width: 95%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: auto;

    span {
      color: black;
    }
  }

  .frame_item_table {
    height: 92%;
    width: 100%;
    // background-color: rebeccapurple;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include mobile {
      box-sizing: border-box;
      overflow: auto;
    }

    .frame_header_table {
      height: 11%;
      width: 100%;
      background: linear-gradient(180deg, #6d80b4 0%, #363f57 100%);
      display: flex;

      @include mobile {
        width: 40rem;
      }

      .frame_left_header_table {
        width: 40%;
        height: 100%;
        // background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .frame_right_header_table {
        width: 60%;
        height: 100%;
        // background-color: rgb(51, 78, 167);
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .frame_first_elememnt {
          width: 100%;
          // background-color: rgb(201, 125, 26);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .frame_second_element {
          width: 100%;
          // background-color: rgb(0, 255, 34);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .frame_thirt_element {
          width: 100%;
          // background-color: aqua;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .frame_body_table {
      height: 78%;
      width: 100%;
      // background-color: #363F57;
      overflow-y: auto;
      position: relative;

      @include mobile {
        width: 40rem;
      }

      .frame_all_item_table {
        width: 100%;
        height: 14%;
        display: flex;

        .frame_item_left_name {
          width: 40%;
          height: 100%;
          display: flex;
          align-items: center;
          border-right: 1px solid grey;

          border-top: 2px solid #c0ccf1;
          // border-bottom: 1px solid #c0ccf1;

          span {
            width: 80%;
            margin: auto;
            text-align: left;
            color: #363f57;
          }

          @include tablet {
            span {
              font-family: $global-font;
              font-size: 1.2rem;
            }
          }

          @include mobile {
            span {
              font-size: 14px;
              font-family: $global-font;
            }
          }
        }

        .frame_item_right_name {
          width: 60%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);

          .frame_value_budget,
          .frame_value_commission,
          .frame_value_spend {
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 2px solid #c0ccf1;
            // border-bottom: 1px solid #c0ccf1;
          }

          .frame_value_budget span,
          .frame_value_commission span,
          .frame_value_spend span {
            color: #363f57;

            @include tablet {
              font-size: 1.2rem;
              font-family: $global-font;
            }

            @include mobile {
              font-size: 14px;
            }
          }
        }
      }

      //   .frame_left_body_table {
      //     width: 40%;
      //     height: auto;
      //     border-right: 1px solid rgb(173, 173, 173);
      //     overflow: auto;

      //     .frame_item_name_table {

      //         height: 14%;
      //         width: 80%;
      //         // background-color: #6d80b4;
      //         display: flex;
      //         justify-content: left;
      //         align-items: center;
      //         margin: auto;
      //         span {
      //             color: #606371;
      //            }

      //     }

      //   }
      //   .frame_right_body_table {
      //     width: 60%;
      //     height: 100%;
      //   }
    }

    .frame_footer_table {
      height: 11%;
      width: 100%;
      background: linear-gradient(180deg, #6d80b4 0%, #363f57 100%);
      display: flex;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 8px;

      @include mobile {
        width: 40rem;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      .frame_left_footer_table {
        width: 40%;
        height: 100%;
        // background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .frame_right_footer_table {
        width: 60%;
        height: 100%;
        // background-color: rgb(51, 78, 167);
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .frame_first_elememnt {
          width: 100%;
          // background-color: rgb(201, 125, 26);
          display: flex;
          justify-content: center;
          align-items: center;

          @include tablet {
            span {
              font-family: $global-font;
              font-size: 1.1rem;
            }
          }

          @include mobile {
            span {
              font-size: 14px;
            }
          }
        }
        .frame_second_element {
          width: 100%;
          // background-color: rgb(0, 255, 34);
          display: flex;
          justify-content: center;
          align-items: center;

          @include tablet {
            span {
              font-family: $global-font;
              font-size: 1.1rem;
            }
          }
          @include mobile {
            span {
              font-size: 14px;
            }
          }
        }

        .frame_thirt_element {
          width: 100%;
          // background-color: aqua;
          display: flex;
          justify-content: center;
          align-items: center;

          @include tablet {
            span {
              font-family: $global-font;
              font-size: 1.1rem;
            }
          }
          @include mobile {
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
