@use '../../scss/' as *;

.frame_total_card_transaction {
  width: 100%;
  height: 100%;
  background: linear-gradient(140deg, #6c7493 0%, #2e3038 100%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: $shadow;

  @include tablet {

    height: 250px !important;
  }

  @include mobile {

    height: 250px !important;
  }
  
  


  .frame_title_icon_value {
    width: 100%;

    @include tablet {

      span {
        font-size: 1.2rem;
      }
      
    }
  
    @include mobile {
  
      span {
        font-size: 1.3rem !important;
      }
    }
    

    span {
      font-size: 1.6rem;
      text-transform: uppercase;
      font-family: $global-font;
    }

    .frame_title_icon {
        margin-bottom: 3%;

      
        @include tablet {
          span  {
            // font-size:1.2rem !important;
          }
         
        }

      .icon_house {
        font-size: 40px;
        margin-left: 2%;
      }

      
    }
  }
}
