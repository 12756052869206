@use "../../scss/" as *;

.main_frame_budget {
  justify-content: center;
  align-items: center;
  height: auto;
  width: 96%;
  margin: auto;

  @include mobile {
    width: 96% !important;
    height: 100%;
    overflow: visible;

    box-sizing: border-box;
    margin-bottom: 7% !important;
  }

  .frame_top_card_bugdet {
    height: 13rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;

    @include tablet {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px;
      height: 25rem;
      overflow: auto;
      box-sizing: border-box;
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .frame_budget_horizontal_chart {
    margin-top: 1rem;

    height: 41.5rem;
    width: 100%;

    border-radius: 10px;
    border: 1.5px solid gray;
  }

  .frame_budgetbymonht {
    background: linear-gradient(140deg, #8b94b6 0%, #40465d 100%);
    height: 39rem;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;

    @include tablet {
      height: 35rem;
    }

    .frame_title_chart_budgets {
      width: 100%;
      height: 15%;
      display: flex;
      justify-content: left;
      align-items: center;

      @include mobile {
        display: flex;
        justify-content: center;
        padding-left: 0px !important;

        span {
          font-size: 1.2rem !important;
          padding-left: 0px !important;
        }
      }

      span {
        font-family: $global-font;
        font-size: 1.4rem;
        text-transform: uppercase;
        padding-left: 3%;
      }
    }

    .frame_colums_chart {
      width: 100%;
      height: 90%;
      margin-top: -2%;

      @include mobile {
        height: 86%;
        overflow: auto;
        box-sizing: border-box;
      }
    }
  }

  span {
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-family: $global-font;
    color: white;
  }

  .frame_line_chart_budget {
    width: 100%;
    height: 25rem;

    display: flex;
    margin-bottom: 20px;
    column-gap: 20px;

    @include tablet {
      display: block;
      height: auto;
      row-gap: 20px;
    }

    @include mobile {
      display: block;
      height: auto;
      row-gap: 20px;
    }

    .frame_left_right_line_chart_budget {
      border-radius: 10px;
      background: linear-gradient(180deg, #7883a0 0%, #475781 100%);

      height: 100%;
      width: 100%;
      margin-bottom: 20px !important;

      @include mobile {
        height: 25rem;
        box-sizing: border-box;
        overflow: auto;
      }

      .frame_left_title_line_chart {
        height: 20%;
        width: 90%;

        display: flex;
        justify-content: left;
        align-items: center;
        margin: auto;

        span {
          color: white;
          text-transform: uppercase;
          font-family: $global-font;
          font-size: 1.4rem;
        }

        @include tablet {
          display: flex;
          justify-content: center;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        @include mobile {
          width: 30rem;
          display: flex;
          justify-content: center;

          padding-top: 0px;
          padding-bottom: 0px;
          height: 18%;

          span {
            font-size: 1rem;
            font-family: $global-font;
          }
        }
      }

      .frame_chart_budget {
        width: 100%;
        height: 80%;

        padding-top: 10px;

        @include tablet {
          width: 100%;
        }

        @include mobile {
          width: 30rem;
          padding-left: 20px;

          padding-top: 10px;

          height: 19rem;
        }
      }
    }
  }

  .frame_table_commission {
    width: 100%;
    height: 40rem;

    margin-bottom: 20px;
  }
}
