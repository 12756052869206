@use './variables' as *;




@mixin mobile {
    @media only screen  and (max-width:$mobile-width) {
        [data-aos] {
            opacity: 1 !important;
            transform: translate(0) scale(1) !important;
        }
        @content;

    }
    
}

@mixin tablet {
    @media only screen  and (max-width:$tablet-width) {
        [data-aos] {
            opacity: 1 !important;
            transform: translate(0) scale(1) !important;
        }
        @content;

    }
    
}

@mixin desktop {
    @media only screen  and (max-width:$tablet-width) {
        @content;

    }
    
}

