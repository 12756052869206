@use "../../../scss/" as *;

.frame_custome_horizontal_barchart {
  width: 100%;
  height: 100%;

  .frame_title_horizontal_barchart {
    width: 100%;
    height: 10%;
    background: linear-gradient(140deg, #8b94b6 0%, #40465d 100%);
    display: flex;
    justify-content: left;
    align-items: center;
    // border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
    // box-shadow: $shadow;
    span {
      width: 95%;
      // background-color: red;
      margin: auto;
      text-align: left;
      font-size: 1.4rem;
      font-family: $global-font;
    }
  }

  .frame_horizontal_barchart {
    height: 90%;
    width: 95%;
    margin: auto;

    @include mobile {
      // background-color: rebeccapurple;
      overflow: auto;
    }

   
  }
}

// barchart---------------------------------------------------

.frame_bar_Chart {
  // color: black;
  height: 48%;
  width: 100%;

  @include mobile {

    width: 200%;
    // background-color: rebeccapurple;
  }

  .title_bar_chart {
    height: 10%;
    width: 100%;
    // background-color: red;
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 1px solid #50618e;
    margin-top: 1%;
    span {
      color: #606371;
    }
  }

  .frame_main_baritem {
    width: 100%;
    // margin: auto;
    height: 89%;
    // display: flex;
    margin-top: 1%;
    

    

    .frame_bar_item_name_percentage_chart {
      width: 100%;
      // height: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      // background-color: red;
      .frame_name_bar_item {
        width: 10%;
        // height: 100%;
        display: flex;
        justify-content: left;
        // background-color: red;
        text-align: left;
        span {
          color: black;
          font-family: $global-font;
          font-size: 1.3rem;
          text-align: left;
        }

        @include mobile {
          width: 25%;
        }
      }

      .frame_percentage_width {
        width: 85%;
        // height: 100%;
        // background-color: red;
        margin-bottom: 1%;
        margin: auto;


        @keyframes fadeDown {
            0% {
                opacity: 0;
                transform: translateY(-40px); /* Start higher */
            }
            30% {
                opacity: 0; /* Halfway point for the fade effect */
                transform: translateY(-30px); /* Move down slightly */
            }
            100% {
                opacity: 1;
                transform: translateY(0); /* End at normal position */
            }
        }
        
        

        .frame_line_percentage_barchart {
          height: 2rem;
          justify-content: center;
          margin: auto;
          animation: fadeDown 1s ease-out;

          .frame_percentage_line {
            height: 100%;
            // background-color: red;

            span {
              font-size: 1rem;
              font-family: $global-font;
              color: black;

              position: relative;
            }

            .frame_main_percentage {
              height: 1rem;
              border-left: 1px solid  #aaa;
              border-right: 1px solid #aaa;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: -8px;

              hr {
                height: 1px;
                width: 100%;
                margin: auto;
                background-color: #aaa;
                border: none;
                border-radius: 5px;
                opacity: 0.8;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Adds a soft shadow */
              }
            }
          }

          .frame_percentage_line_newposition {
            height: 100%;
            // background-color: red;
     
            .frame_new_span_position {
              margin-top: -8px;

              span {
                font-size: 1rem;
                font-family: $global-font;
                color: black;
              }
            }

            .frame_new_span_position_hide {
              margin-top: 0px;

              span {
                font-size: 1rem;
                font-family: $global-font;
                color: black;
              }
            }

            .frame_main_percentage_newposition {
              height: 1rem;
              border-left: 1px solid gray;
              border-right: 1px solid gray;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 8px;

              hr {
                height: 1px;
                width: 100%;
                margin: auto;
                background-color: #aaa;
                border: none;
                border-radius: 5px;
                opacity: 0.8;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Adds a soft shadow */
              }
            }

            .frame_main_percentage_newposition_hide {
              display: none;
            }
          }
        }

        .frame_line_percentage_barchart_hide {
          display: none;
        }

        .frame_width_percentage {
            background: linear-gradient(90deg, #7B84A6, #96A3D6);
            background-size: 0% 100%; /* Start with no width */
            background-position: center; /* Start from the center */
            background-repeat: no-repeat;
            border-radius: 8px;
            height: 2rem;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1px;
            animation: expandFromCenter 3s ease-in-out forwards;

            // overflow: hidden;
            // box-sizing: border-box;
            

            span {
              // color: red;
              font-family: $global-font;
              font-size: 1.1rem;

              // background-color: rgb(107, 137, 145);
            }
          }

          .frame_width_percentage_hide {

            background-color: white;
            background-size: 0% 100%; /* Start with no width */
            background-position: center; /* Start from the center */
            background-repeat: no-repeat;
            border-radius: 8px;
            height: 2rem;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1px;
            animation: expandFromCenter 3s ease-in-out forwards;
            // color: black;
            span {
              color: black;
              font-family: $global-font;
              
            }
          }

          
          @keyframes expandFromCenter {
            0% {
              background-size: 0% 100%; /* Start with no width */
            }
            100% {
              background-size: 100% 100%; /* Expand to full width */
            }
          }
          
      }
    }
  }
}



