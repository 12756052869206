@use '../../scss/' as *;

.frame_main_income_card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(217, 217, 217, 0.3);
    border-radius: 10px;
    padding: 10px;
    align-items: center;
    z-index: 1000;

    @include tablet {
        
        width: 90%;
        height: 70%;

    }

    @include mobile {
        width: 100%;
        height: 78%;


    }
    .frame_title_wallet {
        display: flex;
        justify-content: left;
        align-items: center;
        height: 60%;
        

        @include tablet {

            // background-color: black;
            span {
                font-size: 1.2rem !important;
            }
        
        }
    
        @include mobile {
            span {
                font-size: 0.9rem !important;
            }
        
    
        }

        span {
            display: flex;
            text-align: left;
            color: white;
            font-size: 1.39rem;
            font-family: $global-font;
            text-transform: uppercase;
            font-weight: 350;

            @include tablet {

                // background-color: black;
                span {
                    font-size: 1.2rem !important;
                }
            
            }
        
            @include mobile {
                span {
                    font-size: 1.2rem !important;
                }
            
        
            }


            
        }

        img {
            height: 35px;
            margin-top: -10px;
            margin-left: 5px;

            @include mobile {

                height: 25px;
                margin-top: -10px;
            }
        }
    }
    .frame_income_value {
        height: 50%;
        width: 92%;

        @include tablet {
            width: 95%;
        
        }
    
        @include mobile {
            width: 100%;
    
        }

        span {
            color: white;
            font-size: 1.7rem;
            display: flex;
            text-align: left !important;
            font-family: $global-font;
            text-transform: uppercase;

            @include tablet {

                // background-color: black;
                span {
                    font-size: 1.2rem !important;
                }
            
            }
        
            @include mobile {
                span {
                    font-size: 0.9rem !important;
                }
            
        
            }
        }
    }

}