@use "../../../../scss/" as *;
.main_frame_barItem_tooltip {
  height: 100%;
  // width: 20%;
  // background-color: rgb(68, 85, 43);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  margin: auto;

  .frame_parent_div_tooltip {
    // height: auto;
    height: 100%;
    // background-color: rgb(12, 56, 42);
    align-items: center;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
    margin: auto;

    .frame_top_line_tooltip {
      height: 80% !important;
      // background-color: aqua;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 58%;

      .frame_line_percent_color {
        height: 100%;
        width: 100%;
        // background-color: green;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;

        span {
          font-family: $global-font;
          font-size: 1.1rem;
          line-height: 1.3;

          @include mobile {
            font-size: 1rem;
          }
        }

        .color_line_bar_z_index {
          background: linear-gradient(180deg, #9caffe 0%, #443a6b 100%);
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          z-index: 3;
          position: relative;
        }

        .color_line_bar_z_index_active {
          background: linear-gradient(180deg, #9caffe 0%, #443a6b 100%);
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          z-index: 4;
          position: relative;
        }

        .color_line_bar2_z_index {
          background: linear-gradient(180deg, #9caffe 0%, #443a6b 100%);
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          z-index: 3;
          position: relative;
        }

        .color_line_bar2_z_index_active {
          background: linear-gradient(180deg, #9caffe 0%, #443a6b 100%);
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          z-index: 4;
          position: relative;
        }
      }
    }

    .frame_bottom_line_tooltip {
      height: 20%;
      width: 200px;
      margin: auto;
      overflow: auto;
      box-sizing: content-box;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        line-height: 1.5;
      }
    }

    .frame_bottom_line_tooltip_active {
      height: 20%;
      width: 200px;
      margin: auto;
      overflow: auto;
      box-sizing: content-box;
      // z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        line-height: 1.5;
      }
    }
  }

  .frame_value_barItem_tooltip {
    // background-color: blue;
    height: 15%;
  }

  .frame_line_barItem_tooltip {
    // height: 70%;

    .frame_line_percentage_vertical_chart {
      // background-color: white;
      // max-height: 900px !important;
      border-radius: 10px;
      width: 65%;
      margin: auto;
    }
  }

  .frame_name_barItem_tooltip {
    height: calc(100vh - 90vh);
    width: 90%;
    margin: auto;
    // background-color: aquamarine;
    overflow: auto;
    box-sizing: content-box;
  }
}

.main_frame_element_tooltip_new {
  // background: linear-gradient(180deg, #6D80B4 0%, #3E4760 100%);
  background-color: white;
  // padding-left: 1rem;
  // padding-right: 1rem;
  width: 100%;
  // z-index: 1000;
  overflow: hidden;
  box-sizing: border-box;
  color: black;
  border-radius: 10px;
  border: 2px solid transparent;
  position: relative;

  

  .frame_title {
    display: flex;
    justify-content: left;
    padding-top: 0.8em;
    padding-bottom: 0.7rem;
    width: 100%;
    height: 100%;
    // height: 2rem;
    background: linear-gradient(180deg, #6d80b4 0%, #3e4760 100%);
    // border-radius: 9px;
    position: relative;

    @include mobile {

      justify-content: center;

      span {
        padding-left: 0px;
      }

    }

    span {
      font-family: $global-font;
      text-align: center;
      font-size: 1.2rem;
      text-transform: uppercase;
      padding-left: 1rem;
      color: white;


    }

    @include mobile {
      span {
        font-size: 1.2rem;
      }
    }
  }

  .frame_parent_name_value_line_bar_new {
    padding: 1rem 1rem 0rem 1rem;

    .frame_element_name_value_line_tooltip {
      width: 100%;
      height: 100%;
      padding-bottom: 1rem;

      .frame_element_name_value_tooltip {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        span {
          font-size: 1.2rem;
          font-family: $global-font;
        }

        @include mobile {
          span {
            font-size: 1rem;
          }
        }
      }

      .frame_parent_line_color_new {
        background-color: #f0f0f0;
        // width: 100%;
        height: 100%;

        .line_color_baritem_new {
          height: 1rem;
          background: linear-gradient(180deg, #939cc4 0%, #c7ceec 100%);
          border-radius: 3px;
        }
      }
    }
  }
}
