
@use "../../scss/" as *;

.frame_budget_card_list {

    background: linear-gradient(180deg, #8B94B6 0%, #40465D 100%);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2,1fr);

    border-radius: 10px;
    

    .frame_budget_cart_left_element{
        
        border-right: 1px solid white;
    }

    .frame_budget_cart_right_element {

        height: 100%;

        

        .frame_top_right_element {

            height: 50%;
            display: grid;
            place-items: center;
            
        }

        .frame_bottom_right_element {
            height: 50%;
            border-top: 1px solid white;
            display: grid;
            place-items: center;
        }
        
    }

}

// subbugdetcard-------------------------------------------------

.frame_sub_card_budget {


    width: 90%;
    // background-color: red;
    height: 80%;
  

   .frame_name_sub_card_budget {

    display: flex;
    justify-content: left;
    text-align: left;
    height: 30%;
    align-items: center;
    // background-color: red;

    span {

        font-family: $global-font;
        font-size: 1.1rem;
    }
   }

   .frame_value_sub_card_budget {
    height: 70%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    span {
        // line-height: 2;
        font-size: 1.5rem;
    }
   }


}