@use "../../../../scss/" as *;

.main_frame_barItem_horizontal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    font-family: $global-font;
  }

  .frame_categories_value_horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-color: red;

    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 7%;

    .frame_categories_horizontal_new {
      width:20%;
      // background-color: rebeccapurple;
      // padding-bottom: 8%;
      text-align: left;
      overflow: hidden;
      box-sizing: border-box;

      span {
        color: white;
        font-family: $global-font;
        font-size: 1.1rem;
        font-weight: 500;
        
        
      }

      @include mobile {
        
        // width: 36%;
        white-space: nowrap;    
        overflow: hidden; 
          text-overflow: ellipsis;  
          color: white;  
        span {
          font-size: 1rem;
          
        }
      }
    }

    .fram_parent_linechart_horizontal {
      width: 60% !important;
      // background-color: aqua !important;
      display: flex;
      align-items: center;
      border-radius: 5px;

      background-color: #f0f0f0;

      .frame_line_percentage_horizontal_chart{
        background: linear-gradient(180deg, #697cd1 0%, #c7ceec 100%);
        height: 20px;
        border-radius: 5px;
      }

      
      span {

        padding-left: 3%;
        color: white;
        font-family: $global-font;
        font-size: 1.1rem;
        
      } 
      
    }



    .frame_value_horizontal_chart {
      width: 20%;
      text-align: right;
      span {

        color: white;
        font-family: $global-font;
        font-size: 1.1rem;
      }

      @include mobile {
   
        padding-left: 8px;
        span {
          font-size: 14px;
        }
      }
    }
  }
}

