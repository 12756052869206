@use "../../scss/" as *;

.frame_loading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .span_text_loading {
    font-family: $global-font;
    font-size: 1rem;
    font-weight: 500;
  }
}
