@use "../../scss/" as *;

.main_frame_revenue {
  width: 98%;
  padding-bottom: 1%;
  margin: auto;
  overflow: visible;

  @include tablet {
    height: auto;

    padding-bottom: 10px;
  }

  @include mobile {
    padding-bottom: 0px;
  }

  .frame_datetime_revenue {
    width: 98% !important;
    display: flex;
    margin: auto;
  }

  .main_frame_totalicome {
    height: calc(100vh - 15vh) !important;
    width: 98%;
    margin: auto;
    margin-bottom: 2% !important;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(140deg, #6c7493 0%, #2e3038 100%);
    box-shadow: $shadow;

    @include tablet {
      height: 550px !important;
      margin: auto;
    }

    @include mobile {
      height: 350px !important;
      margin-bottom: 5% !important;
    }

    span {
      text-align: center;
      align-items: center;
      font-size: larger;
      color: white;
    }

    .frame_top_card_title_revnues {
      display: flex;
      justify-content: space-between;
      height: calc(100vh - 84vh);
      padding: 2%;

      @include tablet {
        padding: 0px;
        height: 29%;
        width: 100%;
        margin: auto;
        margin-bottom: 0.7rem;
      }

      @include mobile {
        width: 98%;
        margin: auto;
        height: 30%;
        padding: 0px;
      }

      .frame_card_total_income_revenue {
        width: 380px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          width: 65%;
          height: 100%;
        }
      }

      .frame_top_title_custome_usemap {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-family: $global-font;
          text-transform: uppercase;
          font-size: 1.4rem;
          font-weight: 350;
        }

        @include tablet {
          width: 40%;

          span {
            font-size: 1.2rem;
          }
        }

        @include mobile {
          span {
            font-size: 1rem;
          }
        }
      }
    }

    .frame_geomap_usa_totalicome {
      width: 100%;
      height: 80%;
      margin-top: -3.5% !important;

      @include tablet {
        height: 73.5% !important;
      }

      @include mobile {
        height: 70% !important;
      }
    }
  }

  .main_frame_totalbymonth_revenue {
    height: calc(100vh - 55vh) !important;
    width: 98% !important;
    margin-bottom: 2% !important;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #6c7493 0%, #2e3038 100%);
    box-shadow: $shadow;

    span {
      text-align: center;
      align-items: center;
      font-size: larger;
      color: white;
    }

    @include tablet {
      height: 400px !important;
      margin: auto;
      width: 98% !important;
    }

    @include mobile {
      height: 350px !important;
      margin-bottom: 5% !important;
      width: 98% !important;
    }

    .frame_title_chart_revenue {
      display: flex;
      text-align: left;

      span {
        padding-left: 20px;
        padding-top: 20px;
        font-size: 1.4rem;
        font-family: $global-font;
        text-transform: uppercase;
      }

      @include tablet {
        display: flex;
        justify-content: center;
        text-align: center;
        span {
          font-size: 1.2rem;
        }
      }

      @include mobile {
        display: flex;
        justify-content: center;
        text-align: center;
        span {
          font-size: 1rem;
        }
      }
    }
    .frame_arelinechart_revenue {
      height: 100%;
      margin-top: -25px;
      overflow: hidden;

      @include mobile {
        height: 90%;
        margin-top: -5px;

        overflow: auto;
        box-sizing: border-box;
      }

      .frame_parent_line_Chart_revenue {
        width: 110%;
        height: 100%;
        margin-left: -4.3%;

        @include mobile {
          height: 100%;
          width: 106%;
          display: flex;
          margin: auto;
          justify-content: center;
          margin-left: 1% !important;
        }
      }
    }

    @include tablet {
      height: calc(100vh - 70vh);
    }
  }

  .main_frame_totalbyregion {
    height: calc(100vh - 55vh) !important;
    width: 98%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    margin: auto;
    margin-bottom: 30px !important;

    @include tablet {
      grid-template-columns: repeat(1, 1fr);
      height: 59rem !important;
      margin: auto;
      column-gap: 0px;

      justify-content: space-between;
      gap: 0px;
      margin-bottom: 10px !important;
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
      height: 44em !important;
      margin: auto;
      column-gap: 0px;
      justify-content: space-between;
      row-gap: 15px;
      margin-bottom: 15px !important;
    }

    .total_revenue {
      background: linear-gradient(180deg, #6c7493 0%, #2e3038 100%);
      height: 100%;
      border-radius: 10px;
      box-shadow: $shadow;

      @include tablet {
        box-shadow: none;
        height: 32rem;
      }

      @include mobile {
        height: 22rem;
      }

      .frame_title_top_chart_revenue {
        display: flex;
        text-align: left;

        span {
          padding-left: 20px;
          padding-top: 20px;
          font-size: 1.4rem;
          font-family: $global-font;
        }

        @include mobile {
          span {
            display: flex;
            text-align: center;
            font-size: 1.1rem;
            margin: auto;
            padding-left: 0px;
          }
        }
      }
      .frame_pieChart_revenue {
        height: 100%;
        width: 100%;

        @include tablet {
          margin-top: 0px;
          height: 89%;
        }

        @include mobile {
          display: flex;
          margin-top: 0px;
          margin: auto;
          height: 87%;
          width: 100%;
        }
      }
    }

    .total_categories {
      background: linear-gradient(180deg, #6c7493 0%, #2e3038 100%);
      height: 100%;
      border-radius: 10px;
      display: flex;
      align-items: center;
      box-shadow: $shadow;

      @include tablet {
        height: 25rem;
      }

      @include mobile {
        height: 20rem;
      }

      .frame_custom_line_chart_categories {
        width: 90%;
        height: 90%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .span_style_revenue {
          font-family: $global-font;
          font-size: 1.1rem;
        }
      }
    }

    span {
      text-align: center;
      align-items: center;
      font-size: larger;
      color: rgb(250, 249, 249);
    }
  }

  .main_frame_cardrevenue {
    display: grid;
    width: 98%;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    margin: auto;
    margin-bottom: 1%;

    @include tablet {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px;
      height: 100%;
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px;
      height: 100%;
      margin-bottom: 20px;
    }
  }
}
